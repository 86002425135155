<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="8">
          <CCardGroup>
            <CCard class="p-4">
              <CCardBody>
                <CForm @submit.prevent="submit">
                  <h1>Login</h1>
                  <p class="text-muted">Sign In to your account</p>
                  <CAlert
                    color="warning"
                    closeButton
                    v-if="$store.state.login_message"
                  >
                    {{ $store.state.login_message }}
                  </CAlert>
                  <CInput
                    placeholder="Username"
                    :addInputClasses="user_invalid ? 'is-invalid' : null"
                    v-model="username"
                    @keyup.enter="submit"
                    autocomplete="username"
                  >
                    <template #prepend-content
                      ><CIcon name="cil-user"
                    /></template>
                  </CInput>
                  <CInput
                    placeholder="Password"
                    :type="showPassword ? 'text' : 'password'"
                    v-model="password"
                    @keyup.enter="submit"
                    autocomplete="curent-password"
                    :addInputClasses="pass_invalid ? 'is-invalid' : null"
                  >
                    <template #prepend-content
                      ><CIcon name="cil-lock-locked"
                    /></template>
                    <template #append-content>
                      <!-- <font-awesome-icon
                        :icon="showPassword ? 'eye-slash' : 'eye'"
                        @click="showPassword = !showPassword"
                      /> -->
                      <span @click="showPassword = !showPassword">
                        <CIcon
                          :name="showPassword ? 'cil-eye' : 'cil-eye-slash'"
                        />
                      </span>
                    </template>
                  </CInput>
                  <CRow>
                    <CCol col="6" class="text-left">
                      <CButton
                        color="primary"
                        class="px-4"
                        @click="submit"
                        v-if="!isLoading"
                        >Login</CButton
                      >
                      <CButton color="secondary" class="px-4" disabled v-else>
                        <CSpinner grow size="sm" />
                      </CButton>
                    </CCol>
                    <CCol col="6" class="text-right">
                      <CButton color="link" class="px-0"
                        >Forgot password?</CButton
                      >
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
            <CCard
              color="primary"
              text-color="white"
              class="text-center py-5 d-md-down-none"
              body-wrapper
            >
              <CCardBody>
                <h2>Laptop Plaza</h2>
                <p>The Worlds Largest Laptop Distributor</p>
                <CRow class="justify-content-center">
                  <p class="mb-0 font-italic">
                    Developed by
                    <a
                      class="text-white font-weight-bold"
                      href="https://softronixs.com/"
                      target="_blank"
                      >Softronixs Ltd.</a
                    >
                  </p>
                </CRow>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import {
  cilAsteriskCircle,
  cilPenAlt,
  cilPencil,
  cilRecycle,
} from "@coreui/icons";
export default {
  name: "Login",
  data() {
    return {
      username: null,
      password: null,
      isLoading: false,
      user_invalid: false,
      pass_invalid: false,
      showPassword: false,
      eye: cilAsteriskCircle,
      eyeslash: cilRecycle,
    };
  },
  created() {
    if (this.$store.state.access_token) {
      this.$router.push({ name: "Dashboard" });
    }
  },
  methods: {
    async submit() {
      if (!this.username) {
        this.user_invalid = true;
      } else {
        this.user_invalid = false;
      }
      if (!this.password) {
        this.pass_invalid = true;
      } else {
        this.pass_invalid = false;
      }
      if (this.username && this.password) {
        this.isLoading = true;
        await this.$store
          .dispatch("getToken", {
            username: this.username,
            password: this.password,
          })
          .then((response) => {
            this.isLoading = false;
          });
      }
    },
  },
};
</script>
